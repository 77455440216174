<template>
    <div><!-- --></div>
</template>

<script setup lang="ts">
    import { useStorage } from '@vueuse/core';
    const { t } = useI18n();
    const { $pwa } = useNuxtApp();

    const toastStore = useToast();
    const isMobileDevice = ref(false);
    const hideInstallPrompt = useStorage('vite-pwa:hide-install', false);

    onMounted(() => {
        const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;

        if (/android/i.test(userAgent) || /iPad|iPhone|iPod/.test(userAgent)) {
            isMobileDevice.value = true;
        }

        if (!hideInstallPrompt.value && $pwa && !$pwa.isPWAInstalled) {
            toastStore.addToast({
                title: t('pwa.install_title'),
                message: t('pwa.install_message'),
                severity: 'secondary',
                icon: isMobileDevice.value ? 'install_mobile' : 'install_desktop',
                actions: [
                    {
                        label: t('pwa.install'),
                        click: () => $pwa.install(),
                    },
                    {
                        label: t('pwa.dismiss'),
                        type: 'link',
                        click: (close) => {
                            $pwa.cancelInstall();
                            close();
                        },
                        order: 0,
                    },
                ],
            });
        }
    });
</script>
