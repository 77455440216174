<template>
    <div class="login-view">
        <div class="intro scroll-gradient">
            <article>
                <h1>{{ $t('login.welcome') }}</h1>
                <i18n-t keypath="login.text" tag="p" scope="global">
                    <template #company>
                        {{ $t('general.company') }}
                    </template>
                    <template #br> <br /><br /> </template>
                </i18n-t>
            </article>
        </div>

        <div class="login-options">
            <pwa-install-prompt />
            <vz-button v-if="GOOGLE_ENABLED" class="login google" to="/auth/google" external>
                <template #icon-before>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" height="20">
                        <path
                            d="M113.47 309.408L95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
                            fill="#fbbb00"></path>
                        <path
                            d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
                            fill="#518ef8"></path>
                        <path
                            d="M416.253 455.624l.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
                            fill="#28b446"></path>
                        <path
                            d="M419.404 58.936l-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
                            fill="#f14336"></path>
                    </svg>
                </template>
                <span>{{ $t('login.login-with') }} {{ $t('login.google') }}</span>
            </vz-button>
        </div>
        <vz-footer />
    </div>
</template>

<script setup lang="ts">
    const GOOGLE_ENABLED = useRuntimeConfig().public.features.google ?? true;
</script>

<style scoped lang="scss">
    @use 'assets/mixins/containers.scss' as *;
    @use 'assets/mixins/vertical-rhythm.scss' as *;

    .login-view {
        display: grid;
        gap: 1rem;
        grid-template:
            [intro-start] 'intro' auto [intro-end]
            [login-start] 'login' 1fr [login-end]
            [footer-start] 'footer' min-content [footer-end];
    }

    article {
        margin: 0 auto;

        @include content(90%);

        @include breakpoint(medium) {
            @include content(750px);
        }

        h1 {
            font-size: 1.94em;
            line-height: 1.68em;
            margin-bottom: 0.355em;
            padding-top: 0.484em;
        }

        :deep(h2) {
            font-size: 1.56em;
            line-height: 1.04em;
            margin-bottom: 0;
            padding-top: 0.2em;
        }

        h3 {
            font-size: 1.25em;
            line-height: 1.3em;
            margin-bottom: 1em;
            padding-top: 0.3em;
        }

        h4 {
            font-size: 1em;
            line-height: 1.63em;
            margin-bottom: 1.13em;
            padding-top: 0.5em;
        }

        :deep(p) {
            font-size: 1em;
            line-height: 1.63em;
            margin-bottom: 1.13em;
            padding-top: 0.5em;
        }

        :deep(strong) {
            // color: hsl(var(--primary-900));
        }
    }

    .intro {
        grid-area: intro;
        overflow: scroll;
    }

    .login-options {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-content: center;

        @include breakpoint(small) {
            align-items: start;
            flex-direction: row;
        }
    }

    .footer {
        display: flex;
        flex-direction: row;
        padding: 1rem;
    }

    :deep(.vz-button.login) {
        appearance: none;
        background: unset;
        background-color: #f2f2f2;
        background-image: none;
        border: none;
        border-radius: 20px;
        box-sizing: border-box;
        color: #1f1f1f;
        cursor: pointer;
        display: flex;
        font-family: Roboto, arial, sans-serif;
        font-size: 14px;
        height: 40px;
        justify-content: center;
        letter-spacing: 0.25px;
        max-width: 400px;
        min-width: min-content;
        outline: none;
        overflow: hidden;
        padding: 10px;
        padding: 0 12px;
        place-items: center;
        position: relative;
        text-align: center;
        transition:
            background-color 0.218s,
            border-color 0.218s,
            box-shadow 0.218s;
        user-select: none;
        vertical-align: middle;
        white-space: nowrap;
        width: auto;

        span {
            flex-grow: 1;
            font-family: Roboto, arial, sans-serif;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            vertical-align: top;
        }

        svg {
            height: 20px;
            margin-right: 12px;
            min-width: 20px;
            width: 20px;
        }

        &:not(:disabled):hover {
            box-shadow:
                0 1px 2px 0 rgb(60 64 67 / 30%),
                0 1px 3px 1px rgb(60 64 67 / 15%);
        }
    }
</style>
